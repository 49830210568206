import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { pushError } from '@app/_helpers/globalErrorHandler';
import { SlideUpDown } from '@app/animations/fade';
import { AppService } from '@app/app.service';
import { FeedHiddenItemsDialogComponent } from '@app/components/feed-hidden-items-dialog/feed-hidden-items-dialog.component';
import { I18nService } from '@app/core';
import { CreateProjectComponent } from '@app/shared/dialogs/create-project/create-project.component';
import {
  FeedbackDialogComponent,
  FeedbackDialogConfig,
  FeedbackType,
} from '@app/shared/dialogs/feedback-dialog/feedback-dialog.component';
import { RecordToolbarService } from '@app/shared/record-toolbar/record-toolbar.service';
import { TimeTrackerCalendarStepperCreateDialogComponent } from '@app/shared/time-tracker-calendar-stepper-create-dialog/time-tracker-calendar-stepper-create-dialog.component';
import { ShellComponent } from '@app/shell/shell.component';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { endOfDay, endOfISOWeek, isAfter, startOfDay, startOfISOWeek, startOfMinute } from 'date-fns/esm';
import { MediaObserver } from 'ngx-flexible-layout';
import { from } from 'rxjs';
import { map, shareReplay, takeUntil, tap } from 'rxjs/operators';
import { firstBy } from 'thenby';
import {
  ApplicationSettingsQuery,
  ClientsQuery,
  FeedEntry,
  FeedQuery,
  FeedService,
  Logger,
  Project,
  ProjectsQuery,
  ProjectsService,
  Scope,
  Task,
  UserService,
  UserSettingsQuery,
} from 'timeghost-api';

import { FeedPageService, FeedSuggestionsType } from './feed.service';

const log = new Logger('FeedComponent');
@UntilDestroy()
@Component({
  selector: 'app-feed',
  templateUrl: './feed.component.html',
  styleUrls: ['./feed.component.scss'],
  animations: [SlideUpDown],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'feed-host',
  },
})
export class FeedComponent implements OnInit, AfterViewInit {
  readonly requestError$ = this.feedQuery.selectError().pipe(
    map((err: Error) => {
      if (!err) return err;
      let errChecks = {
        isTimeout:
          err?.message &&
          ['timeout', 'token renewal operation'].filter((x) => err.message.indexOf(x) !== -1).length > 0,
        isAuthed:
          err?.message &&
          ['Raw ID Token Value: null', 'idToken is null'].filter((x) => err.message.indexOf(x) !== -1).length === 0,
      };
      return {
        ...err,
        ...errChecks,
        message: errChecks.isTimeout ? 'feed.timeout' : !errChecks.isAuthed ? 'utils.not-authenticated' : err.message,
      };
    }),
  );

  showBooked$ = this.userSettingsQuery.select((x) => x.settings.feedShowBooked);
  readonly viewDate$ = this.feedPageService.viewDate$;
  readonly viewDate$status = this.feedPageService.viewDate$status;
  get isLoading() {
    return this.feedPageService.isLoading.value || this.feedPageService.feedLoading;
  }
  set isLoading(val: boolean) {
    this.feedPageService.isLoading.update(val);
  }
  readonly isLoading$ = this.feedPageService.isLoading$;
  readonly feedEntries$ = this.feedPageService.feedEntries.asObservable();
  readonly filterScopes$ = this.feedPageService.filteredScopes$.asObservable();
  readonly availableScopes$ = this.feedPageService.availableScopes$;
  readonly availableScopes$custom = this.feedPageService.availableScopes$custom;
  get filterScopes() {
    return this.feedPageService.filterScopes.value;
  }
  set filterScopes(val: Partial<{ [key: string]: boolean }>) {
    this.feedPageService.filterScopes.update(val);
  }

  ngOnInit(): void {}
  get rangeType() {
    const w = this.appSettings.getValue().config?.feedView;
    return w === 1 ? 'week' : w === 0 ? 'day' : 'day';
  }
  toggleRange() {
    this.appSettings.updateByKey('config.feedView', this.rangeType === 'week' ? 0 : 1);
  }
  today() {
    if (this.rangeType === 'week')
      return this.updateRange(new DateRange(startOfISOWeek(new Date()), endOfISOWeek(new Date())));
    return this.updateRange(new DateRange<Date>(startOfDay(new Date()), endOfDay(new Date())));
  }
  readonly updateRange = this.feedPageService.updateRange.bind(
    this.feedPageService,
  ) as typeof this.feedPageService.updateRange;
  get eventFilterSearch() {
    return this.feedPageService.search;
  }
  ngAfterViewInit(): void {}
  enabledScopes$ = this.userSettingsQuery.select((x) => x.enabledGraphScopes);
  scopes: typeof Scope = Scope;
  hideLoading = false;
  toggleHide(entry: FeedEntry) {
    this.hideLoading = true;
    return this.feedService
      .toggleHideEntryInFeed(entry)
      .then(() => {
        this.hideLoading = false;
      })
      .catch((err) => {
        pushError(err);
        this.hideLoading = false;
      });
  }
  toggleFilterScope(scope: Scope | string) {
    if (!scope) return;
    this.feedPageService.toggleFilterScope(scope);
    this._changeDetectorRef.markForCheck();
  }
  changeShowBooked = this.feedPageService.changeShowBooked.bind(this.feedPageService);
  get showBookedLoading() {
    return this.feedPageService.showBookedLoading;
  }
  readonly filteredEntries$ = this.feedPageService.filteredEntries$.pipe(
    shareReplay(),
    tap(() => {
      this._changeDetectorRef.markForCheck();
    }),
  );
  readonly filteredEntries$hasHidden = this.feedEntries$.pipe(
    map((entries) => entries.findIndex((x) => x.hidden) !== -1),
  );

  get currentLang() {
    return this.userSettingsQuery.getValue().settings.languageSetting;
  }
  get currentLocale() {
    return this.i18nService.language;
  }
  get dateFormat() {
    return this.userSettingsQuery.getValue().settings.timeFormat24h === true ? 'de' : 'en';
  }
  get HFormat() {
    return this.appService.timeFormat;
  }
  get endReached() {
    return !this.feedQuery.getNextDate();
  }
  @ViewChild('viewPort')
  dataViewPort: CdkVirtualScrollViewport;
  constructor(
    public shell: ShellComponent,
    private feedQuery: FeedQuery,
    private dialog: MatDialog,
    private projectsQuery: ProjectsQuery,
    private clientsQuery: ClientsQuery,
    private feedService: FeedService,
    private userSettingsQuery: UserSettingsQuery,
    private userService: UserService,
    private appService: AppService,
    private _changeDetectorRef: ChangeDetectorRef,
    private feedPageService: FeedPageService,
    private cdr: ChangeDetectorRef,
    private i18nService: I18nService,
    private appSettings: ApplicationSettingsQuery,
    private media: MediaObserver,
    private projectsService: ProjectsService,
    private recordService: RecordToolbarService,
    private translateService: TranslateService,
  ) {}
  isMobile$ = this.media.asObservable().pipe(map((x) => x.filter((y) => ['sm', 'xs'].includes(y.mqAlias)).length > 0));
  get isTeams() {
    return this.appService.isTeams();
  }
  get isMobile() {
    return this.media.isActive(['sm', 'xs']);
  }
  isDefaultProject(id: string) {
    return this.projectsQuery.getEntity(id)?.useAsDefault === true;
  }
  isDefaultClient(id: string) {
    return this.clientsQuery.getEntity(id)?.useAsDefault === true;
  }
  reloadCalendar() {
    return this.feedPageService.reloadCalendar();
  }
  get minRangeStart() {
    return this.feedPageService.minRangeStart;
  }
  get minValidation() {
    return isAfter(this.minRangeStart, this.feedPageService.viewDate.value.start);
  }
  prev() {
    return this.feedPageService.prev();
  }
  next() {
    return this.feedPageService.next();
  }
  locationReload() {
    return window.location.reload();
  }
  async drop({ data }: { data: FeedEntry }, project?: Project) {
    return await this.feedPageService.createTime({ data }, project);
  }
  openFeedHiddenDialog() {
    return this.dialog.open(FeedHiddenItemsDialogComponent, {
      data: {
        viewDate: { ...this.feedPageService.viewDate.value },
      },
    });
  }
  openCreateProjectDialog() {
    return this.dialog.open(CreateProjectComponent, {
      data: {
        search: '',
      },
    });
  }
  trackFeedGroup(index: number, { date }: { date: string }) {
    return date;
  }
  trackId(index: number, { id }: { id: string }) {
    return id;
  }
  get hasMore() {
    return this.feedQuery.getHasMore();
  }
  openBugReport(error: Error) {
    return this.dialog.open(FeedbackDialogComponent, {
      data: <FeedbackDialogConfig>{
        description: `\n\nError Stacktrace:\n\`\`\`\n${error}\n\`\`\``,
        type: FeedbackType.BUG,
      },
    });
  }
  getSuggestionsFromFeedItem(entry: FeedEntry) {
    return this.projectsService.findByTaskName(entry.name);
  }
  togglePinProject(id: string) {
    return (
      this.isProjectPinned(id) ? this.userService.removePinnedProject(id) : this.userService.addPinnedProject(id)
    ).toPromise();
  }
  isProjectPinned(id: string) {
    return this.userSettingsQuery.getValue().pinnedProjects?.findIndex((x) => x === id) >= 0;
  }
  async submitFeedItem(data: FeedEntry, projectId: string, _task: Task, showModal?: boolean) {
    const project = this.projectsQuery.getEntity(projectId),
      start = startOfMinute(new Date(data.start)),
      end = startOfMinute(new Date(data.end || start)),
      task = _task?.id === '_internal' ? null : _task;
    log.debug('quickbook', data, project, task);
    if (!showModal)
      this.recordService.save({
        name: data.name,
        start: start.toISOString(),
        end: end.toISOString(),
        outlookCalenderReference: data.id,
        project,
        task,
        billable: !!project?.billable,
      });
    else
      this.dialog.open(TimeTrackerCalendarStepperCreateDialogComponent, {
        data: {
          start: new Date(data.start),
          end: new Date(data.end || data.start),
          title: data.name,
          project,
          task,
          billable: !!project?.billable,
          lang: {
            submit: 'feed.entry.save',
          },
          outlookRefId: data.id,
          skipProjectStepper: true,
        } as any,
      });
  }

  feedEntryMenuPosition = { x: '0px', y: '0px' };
  get feedSuggestionsCacheId() {
    return this.feedPageService.feedSuggestionsCacheId;
  }
  set feedSuggestionsCacheId(val: string) {
    this.feedPageService.feedSuggestionsCacheId = val;
  }
  get feedSuggestions() {
    return this.feedPageService.feedSuggestions.value;
  }
  set feedSuggestions(val: typeof this.feedPageService.feedSuggestions.value) {
    this.feedPageService.feedSuggestions.update(val);
  }
  openFeedEntryContextMenu(event: MouseEvent, trigger: MatMenuTrigger, data: { entry: FeedEntry }) {
    event.stopPropagation(), event.preventDefault();
    this.feedEntryMenuPosition.x = event.clientX + 'px';
    this.feedEntryMenuPosition.y = event.clientY + 'px';
    trigger.menuData = data;
    if (this.feedSuggestionsCacheId !== data.entry.id) {
      trigger.menuData.suggestionsLoading = true;
      const noTaskName = this.translateService.instant('task.none');
      from(this.getSuggestionsFromFeedItem(data.entry))
        .pipe(takeUntil(trigger.menuClosed))
        .toPromise()
        .then((x) =>
          x
            ? ((values) => (values?.length > 0 ? values : null))(
                Object.values(
                  x
                    ?.filter((y) => !!y.project?.id)
                    .uniqBy(({ project, task }) => `${project.id}${task?.id}`)
                    .sort(firstBy('project.name').thenBy((y) => y.task?.name || noTaskName))
                    .reduce(
                      (obj, r) => {
                        if (!obj[r.project.id])
                          obj[r.project.id] = {
                            ...r.project,
                            cosmosEntityName: 'projects',
                            ...((prj) => {
                              if (!prj) return {};
                              return {
                                useAsDefault: prj.useAsDefault,
                              };
                            })(this.projectsQuery.getEntity(r.project.id)),
                            tasks: ((tasks) => [{ id: '_internal', name: 'task.none' }, ...tasks])(
                              r.task?.id ? [r.task as any] : [],
                            ),
                          };
                        else if (r.task?.id) obj[r.project.id].tasks.push(r.task as any);
                        return obj;
                      },
                      <{ [key: string]: FeedSuggestionsType }>{},
                    ),
                ),
              )
            : null,
        )
        .then((x) => {
          this.feedSuggestionsCacheId = data.entry.id;
          this.feedSuggestions = trigger.menuData.suggestions = x;
          log.debug('suggestions', x);
          trigger.menuData.suggestionsLoading = false;
          trigger.menu.lazyContent?.detach(), trigger.menu.lazyContent?.attach(trigger.menuData);
        })
        .catch((err) => {
          pushError(err);
          trigger.menuData.suggestionsLoading = false;
          this.feedSuggestionsCacheId = null;
        });
    } else {
      trigger.menuData.suggestionsLoading = false;
      trigger.menuData.suggestions = this.feedSuggestions;
    }
    trigger.menu.focusFirstItem('mouse');
    trigger.openMenu();
  }
}
