import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ParseFeedDescriptionPipe } from './parse-feed-description.pipe';

@NgModule({
  declarations: [ParseFeedDescriptionPipe],
  imports: [CommonModule, TranslateModule],
  exports: [ParseFeedDescriptionPipe],
  providers: [],
})
export class ParseFeedDescriptionModule {}
