import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FixedGroupHeaderService {
  private _sections = new BehaviorSubject<{ id: string; active: boolean }[]>([]);
  readonly sections$ = this._sections.asObservable().pipe(distinctUntilChanged());
  get sections() {
    return this._sections.getValue();
  }
  set sections(val: { id: string; active: boolean }[]) {
    this._sections.next(val);
  }
  private _sectionsData = new BehaviorSubject<{ [key: string]: any }>({});
  readonly sectionsData$ = this._sectionsData.asObservable().pipe(distinctUntilChanged());
  get sectionsData() {
    return this._sectionsData.getValue();
  }
  set sectionsData(val: { [key: string]: any }) {
    this._sectionsData.next(val);
  }

  constructor() {}

  setSection(id: string, active: boolean) {
    this.sections = [...this.sections.filter((x) => x.id !== id), { id, active }];
  }
  setSectionData(id: string, data: any) {
    this.sectionsData[id] = data;
  }
}
