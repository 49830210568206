import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CalendarInsertTimeDialogModule } from './calendar-insert-time-dialog/calendar-insert-time-dialog.module';

import { MaterialModule } from '@app/material.module';
import { TranslateModule } from '@ngx-translate/core';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { CustomDirectivesModule } from '@app/_directives/custom-directives.module';
import { DayPickerPopoverModule } from '@app/components/day-picker-popover/day-picker-popover.module';
import { FeedHiddenItemsDialogModule } from '@app/components/feed-hidden-items-dialog/feed-hidden-items-dialog.module';
import { ShellMenuToggleModule } from '@app/components/shell-menu-toggle/shell-menu-toggle.module';
import { FabricBaseIconModule } from '@app/modules/fabric/fabric-base-icon/fabric-base-icon.module';
import { FabricFileIconModule } from '@app/modules/fabric/fabric-file-icon/fabric-file-icon.module';
import { TgToolbarModule } from '@app/shared/dialogs/tg-toolbar/tg-toolbar.module';
import { EstimationStatisticsGraphModule } from '@app/shared/estimation-statistics-graph/estimation-statistics-graph.module';
import { TimeRangePickerModule } from '@app/shared/time-range-picker/time-range-picker.module';
import { TimeTrackerCalendarStepperCreateDialogModule } from '@app/shared/time-tracker-calendar-stepper-create-dialog/time-tracker-calendar-stepper-create-dialog.module';
import { UserAvatarModule } from '@app/shared/user-avatar/user-avatar.module';
import { UserMenuModule } from '@app/shared/user-menu/user-menu.module';
import { DateFnsModule } from 'ngx-date-fns';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FeedFilterEventItemComponent } from './feed-filter-event-item/feed-filter-event-item.component';
import { FeedRoutingModule } from './feed-routing.module';
import { FeedComponent } from './feed.component';
import { FeedPageService } from './feed.service';
import { FixedGroupHeaderDirective } from './fixed-group-header.directive';
import { FixedGroupHeaderService } from './fixed-group-header.service';
import { GroupByKeyPipe } from './pipes/groupByKey.pipe';
import { ParseCustomScopePipe } from './pipes/parse-custom-scope.pipe';
import { ParseFeedDescriptionModule } from './pipes/parse-feed-description.module';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FeedRoutingModule,
    CustomDirectivesModule,
    MaterialModule,
    TranslateModule,

    UserMenuModule,
    InfiniteScrollModule,
    UserAvatarModule,
    TimeTrackerCalendarStepperCreateDialogModule,
    CalendarInsertTimeDialogModule,
    FabricBaseIconModule,
    FabricFileIconModule,
    ScrollingModule,
    DateFnsModule,
    EstimationStatisticsGraphModule,
    ShellMenuToggleModule,
    TimeRangePickerModule,
    DayPickerPopoverModule,
    FeedHiddenItemsDialogModule,
    ParseFeedDescriptionModule,
    TgToolbarModule,
  ],
  declarations: [
    FeedComponent,
    GroupByKeyPipe,
    FeedFilterEventItemComponent,
    ParseCustomScopePipe,
    FixedGroupHeaderDirective,
  ],
  providers: [FixedGroupHeaderService],
})
export class FeedModule {
  /**
   * allows saving state without refetching on page load
   */
  static forRoot(): ModuleWithProviders<FeedModule> {
    return {
      ngModule: FeedModule,
      providers: [FeedPageService],
    };
  }
}
