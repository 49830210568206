<mat-dialog-content>
  <div class="flex flex-col gap-2">
    <div
      *ngFor="let entry of entries$ | async; let last = last; trackBy: trackId"
      [class.brd-b]="!last"
      class="flex flex-row items-center justify-start gap-2 py-2"
    >
      <div class="flex flex-col gap-1">
        <div class="mat-typography">{{ entry.name | truncate: 100 }}</div>
        <div class="flex flex-row items-center justify-start">
          <button mat-button class="mat-button-small" (click)="removeFromHidden(entry.id)">
            {{ 'feed.removeHidden' | translate }}
          </button>
        </div>
      </div>
      <div style="height: 32px; width: 32px" class="!ml-auto">
        <img *ngIf="entry.iconUrl != null; else msIcon" [src]="entry.iconUrl" class="h-full" />
        <ng-template #msIcon>
          <ms-icon style="font-size: 32px" class="h-full" [icon]="entry.icon"> </ms-icon>
        </ng-template>
      </div>
    </div>
  </div>
</mat-dialog-content>
