import { ScrollingModule as ExperimentalScrollingModule } from '@angular/cdk-experimental/scrolling';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CustomPipesModule } from '@app/_pipes/custom-pipes.module';
import { DurationInputControlModule } from '@app/components/duration-input-control/duration-input-control.module';
import { MaterialModule } from '@app/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { DateFnsModule } from 'ngx-date-fns';
import { EstimationStatisticsGraphModule } from '../estimation-statistics-graph/estimation-statistics-graph.module';
import { PlainStepperModule } from '../plain-stepper/plain-stepper.module';
import { TimeInputModule } from '../time-input/time-input.module';
import { TimeTrackerCalendarCreateDialogModule } from '../time-tracker-calendar-create-dialog/time-tracker-calendar-create-dialog.module';
import { ProjectListModule } from './project-list/project-list.module';
import { TimeTrackerCalendarStepperCreateDialogComponent } from './time-tracker-calendar-stepper-create-dialog.component';

@NgModule({
  declarations: [TimeTrackerCalendarStepperCreateDialogComponent],
  imports: [
    CommonModule,
    MaterialModule,
    CustomPipesModule,

    TimeInputModule,
    DurationInputControlModule,
    FormsModule,
    ReactiveFormsModule,
    DateFnsModule,
    TranslateModule,
    CdkStepperModule,
    TimeTrackerCalendarCreateDialogModule,
    EstimationStatisticsGraphModule,
    RouterModule,
    ScrollingModule,
    ExperimentalScrollingModule,
    PlainStepperModule,
    ProjectListModule,
  ],
})
export class TimeTrackerCalendarStepperCreateDialogModule {}
