import { Component, Inject, OnInit } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { isSameDay } from 'date-fns/esm';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { FeedQuery, FeedService } from 'timeghost-api';

@Component({
  selector: 'tg-feed-hidden-items-dialog',
  templateUrl: './feed-hidden-items-dialog.component.html',
  styleUrls: ['./feed-hidden-items-dialog.component.scss'],
})
export class FeedHiddenItemsDialogComponent implements OnInit {
  constructor(
    private feedQuery: FeedQuery,
    private feedService: FeedService,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private ref: MatDialogRef<FeedHiddenItemsDialogComponent>,
  ) {}

  ngOnInit(): void {
    this.ref.updateSize('480px');
    this.ref.addPanelClass(['mat-dialog-relative']);
  }

  private _viewDate = new BehaviorSubject<Date>(null);
  readonly viewDate$ = this._viewDate.asObservable().pipe(distinctUntilChanged());
  get viewDate() {
    return this._viewDate.getValue();
  }
  set viewDate(val: Date) {
    this._viewDate.next(val);
  }
  trackId(index: number, { id }: { id: string }) {
    return id;
  }
  entries$ = combineLatest([this.feedQuery.selectAll(), this.viewDate$]).pipe(
    map(([entries, date]) => {
      return entries.filter((x) => x.hidden && (date ? isSameDay(new Date(x.start), date) : true));
    }),
  );
  removeFromHidden(id: string) {
    const entity = this.feedQuery.getEntity(id);
    if (entity)
      return this.feedService.toggleHideEntryInFeed(entity).then(() => {
        if (this.feedQuery.getCount((x) => x.hidden) === 0) this.ref.close();
      });
  }
}
