import { CommonModule, formatDate } from '@angular/common';
import { Injectable, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomPipesModule } from '@app/_pipes/custom-pipes.module';
import { DurationInputControlModule } from '@app/components/duration-input-control/duration-input-control.module';
import { TaskPickerDialogModule } from '@app/components/task-picker-dialog/task-picker-dialog.module';
import { UserSinglePickerDialogModule } from '@app/components/user-single-picker-dialog/user-single-picker-dialog.module';
import { DisableControlModule } from '@app/directives/disable-control/disable-control.module';
import { MaterialModule } from '@app/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { CalendarDateFormatter, CalendarModule, DateAdapter, DateFormatterParams } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { DateFnsModule } from 'ngx-date-fns';
import { TimeInputModule } from '../time-input/time-input.module';
import { UserAvatarModule } from '../user-avatar/user-avatar.module';
import { TimeTrackerCalendarCreateDialogComponent } from './time-tracker-calendar-create-dialog.component';

@Injectable()
class CustomDateFormatter extends CalendarDateFormatter {
  public dayViewHour({ date, locale }: DateFormatterParams): string {
    return formatDate(date, 'EEE', locale); // use short week days
  }
}
@NgModule({
  declarations: [TimeTrackerCalendarCreateDialogComponent],
  imports: [
    CommonModule,
    MaterialModule,
    CustomPipesModule,

    TimeInputModule,
    DurationInputControlModule,
    FormsModule,
    ReactiveFormsModule,
    DateFnsModule,
    TranslateModule,
    TaskPickerDialogModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    UserAvatarModule,
    UserSinglePickerDialogModule,
    DisableControlModule,
  ],
  exports: [TimeTrackerCalendarCreateDialogComponent],
})
export class TimeTrackerCalendarCreateDialogModule {}
