import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/core';
import { FeedComponent } from './feed.component';

const routes: Routes = [
  // Module is lazy loaded, see app-routing.module.ts
  {
    path: '',
    component: FeedComponent,
    data: { title: extract('Feed') },
    children: [
      {
        path: 'feed-scopes',
        loadChildren: () =>
          import('@app/account-scope-dialog/account-scope-dialog.module').then((x) => x.AccountScopeDialogModule),
        data: { state: 'scopes' },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class FeedRoutingModule {}
